.home {
  background-color: black;
  margin: 0;
  padding: 0;
  /* margin-top: -30px; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(./assets/Rectangle.png), url(./assets/sliderBg.png);
  background-size: 100% 452px, 100% 600px;
  background-position: left top 800px, left top 1250px;
  background-repeat: no-repeat;
}

.homemint{
  background-color: black;
  margin: 0;
  padding: 0;
  /* margin-top: -30px; */
  width: 100%;
  height: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
