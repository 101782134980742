.headerSocial {
  width: 10%;
  height: 100%;
  display: flex;
}
.social {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid lightgrey;
  margin: 4px;
  cursor: pointer;
}
.social img {
  height: 20px;
  width: 20px;
}
.socialIcon {
  height: 617px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 180px;
  background: linear-gradient(
    180.01deg,
    rgba(0, 0, 0, 0) -7.78%,
    rgba(210, 115, 31, 0.75) 47.23%,
    rgba(0, 0, 0, 0) 99.99%
  );
}

@media screen and (max-width: 768px) {
  .headerSocial {
    width: 10%;
    height: 100%;
    display: none;
  }
}
