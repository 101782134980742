.advantageHolder {
  width: 1140px;
  height: 500px;
}
.advantageHolder h2 {
  font-family: Euclid Circular A;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  color: #ffffff;
}
#holders {
  height: 70px;
  margin-left: 72%;
}
.holderAdvantages {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.holderAdvantage {
  width: 264px;
  height: 215px;
  background: #090909;
  border: 1px solid #2d2d2d;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 30px;
}
.holderAdvantage h4 {
  font-family: Euclid Circular A;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 10px;
  color: #f08324;
}
.holderAdvantage p {
  font-family: Euclid Circular A;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 24px;

  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .advantageHolder {
    width: 300px;
    height: 100%;
  }
  .advantageHolder h2 {
    font-family: Euclid Circular A;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
  }
  #holders {
    height: 31px;
    margin-left: 57%;
  }
  .holderAdvantages {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .holderAdvantage {
    margin-bottom: 20px;
  }
  .holderAdvantage h4 {
    font-family: Euclid Circular A;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 22px;
    color: #f08324;
  }
  .holderAdvantage p {
    font-family: Euclid Circular A;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #ffffff;
  }
  .holderAdvantage {
    width: 264px;
    height: 154px;
    background: #090909;
    border: 1px solid #2d2d2d;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 30px;
  }
}
