@font-face {
  font-family: Euclid Circular A;
  src: url(../../fonts/eculid_bold.ttf);
}
@font-face {
  font-family: euclid circular light;
  src: url(../../fonts/Eucli_light.ttf);
}

.navbar {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar a {
  text-decoration: none;
  font-family: euclid circular light;
  color: white;
}
.navbar h1 {
  font-family: Euclid Circular A;
  color: white;
}
.menu a {
  text-decoration: none;
  font-family: euclid circular light;
  color: white;
  margin-right: 40px;
  font-weight: 300;
  font-size: 21px;
}

.menu :hover {
  text-decoration: none;
  font-family: euclid circular light;
  color: #f08324;
  margin-right: 40px;
  font-weight: 310;
  font-size: 24px;
}

.logobinart :hover{
  color: #f08324;

}
#mintButton {
  width: 153px;
  height: 62px;
  background: linear-gradient(270deg, #f08324 -10.71%, #ac3c09 183.33%);
  border-radius: 22px;
  border: none;
  color: white;
  font-family: euclid circular light;
  color: white;
  font-weight: 500;
  font-size: 22px;
  margin-right: 18px;
}

/* button:hover {
  background-color: bisque;
} */


#hoverend {
  width: 157px;
  height: 65px;
  background-color: rgb(243, 183, 72);
  border-radius: 22px;
  border: none;
  color: white;
  font-family: euclid circular light;
  color: white;
  font-weight: 500;
  font-size: 22px;
  margin-right: 18px;
}

#connectWalletButton {
  width: 236px;
  height: 65px;
  background: rgba(255, 255, 255, 0.13);
  border-radius: 22px;
  font-family: euclid circular light;
  color: white;
  font-weight: 500;
  font-size: 22px;
  border: none;
}
@media screen and (max-width: 768px) {
  .menu {
    margin-left: 5px;
    margin-top: -17px;
  }
  .navButons {
    margin-left: 6px;
    margin-top: -13px;
  }
  .navbar {
    width: 300px !important;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar h1 {
    font-family: Euclid Circular A;
    color: white;
    font-size: 10px;
  }
  .menu a {
    text-decoration: none;
    font-family: euclid circular light;
    color: white;
    margin-right: 0px;
    font-weight: 300;
    font-size: 8px;
    margin-left: 4px;
  }
  .navbar {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  #mintButton {
    width: 35px;
    height: 26px;
    background: linear-gradient(270deg, #f08324 -10.71%, #ac3c09 183.33%);
    border-radius: 9px;
    border: none;
    color: white;
    font-family: euclid circular light;
    color: white;
    font-weight: 500;
    font-size: 8px;
    margin-right: 5px;
  }
  #connectWalletButton {
    width: 79px;
    height: 26px;
    background: rgba(255, 255, 255, 0.13);
    border-radius: 6px;
    font-family: euclid circular light;
    color: white;
    font-weight: 500;
    font-size: 9px;
    border: none;
  }
}
