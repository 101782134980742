.mainTitle {
  font-family: Euclid Circular A;
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  color: white;
  margin-right: 40px;
}
.firstLine {
  display: flex;
  margin-top: 60px;
}
.secondLine {
  display: flex;
}
.mainPara {
  font-family: Euclid Circular A;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: white;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .firstLine {
    display: flex;
    margin-top: 10px;
  }
  .mainTitle {
    font-family: Euclid Circular A;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    color: white;
    margin-right: 8px;
  }
  .firstLine img {
    height: 26px;
  }
  .secondLine img {
    height: 26px;
    margin-right: 8px !important;
  }
  .mainPara {
    font-family: Euclid Circular A;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: white;
    text-align: center;
  }
  #headerBigLogo {
    height: 150px;
    margin-top: 20px !important;
  }
}
