.hero {
  width: 100%;
  height: 799px;
  background-image: url(../../assets/HeroBackground.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
}
.heromint {
  width: 100%;
  height: 799px;
  background-image: url(../../assets/HeroBackground.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;

}

@media screen and (max-width: 768px) {
  .hero {
    width: 100%;
    height: 430px;
    background-image: url(../../assets/HeroBackground.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
  }
}
