.faq {
  height: 700px;
  width: 1140px;
}
.faq h2 {
  font-family: Euclid Circular A;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  /* identical to box height */

  color: #ffffff;
}
.faqh2 {
  margin-top: -50px !important;
}
.accordion {
  width: 1140px;
  height: 346px;
  background: rgba(123, 123, 123, 0.1);
  backdrop-filter: blur(101px);
  border-radius: 15px;
  padding-left: 60px;
}
.firstAccordion {
  width: 500px;
  height: 100%;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.4);
  margin-top: 30px;
  margin-right: 20px;
}
.question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
}
.question img {
  width: 42px;
  height: 42px;
  cursor: pointer;
}
.question p {
  font-family: Euclid Circular A;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 24px;

  color: #ffffff;
}
#answer {
  font-family: Euclid Circular A;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #ffffff;
  padding: 20px;
}
.firsLineAcc {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .faq {
    height: 100%;
    width: 300px;
  }
  .faq h2 {
    font-family: Euclid Circular A;
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 30px;
    color: #ffffff;
  }
  #holders {
    height: 31px;
    margin-left: 70%;
  }
  .accordion {
    width: 100%;
    height: 100%;
    background: rgba(123, 123, 123, 0.1);
    -webkit-backdrop-filter: blur(101px);
    backdrop-filter: blur(101px);
    border-radius: 15px;
    padding-left: 18px;
  }
  .firstAccordion {
    width: 258px;
    height: 100%;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.4);
    margin-top: 30px;
    margin-right: 20px;
  }
  .firstAccordion {
    width: 258px;
    height: 100%;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.4);
    margin-top: 10px;
    margin-right: 20px;
  }
}
